.cmodal {
  .Subtitle {
    color: #363B64;
    font-family: "Poppins-Medium";
    align-self: center;
    text-align: center;
    font-size:18px;
    padding-bottom: 0 !important;
  }
}
