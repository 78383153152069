.container {
  display: flex;
  color: #363b64;
  .nameNrole {
    // padding: 1.5rem;
    font-size: 10px;
    .name {
      font-family: "Poppins-SemiBold";
      font-size: 0.8rem;
      padding-top: 3px;
      text-align: right;
      &:hover {
        color: #3f8dfd;
        transition: ease-in-out 0.1s;
      }
    }
    .role {
      // width: 100%;
      // text-align: right !important;
      float: right;
      .logout {
        background: transparent;
        border: none;
        color: #a098ae;
        font-family: "Poppins-Regular";
      }
      // float: right;
    }
  }
  .image {
    // width: fit-content;
    // padding: 1rem;
    .adminimage {
      height: 2.5rem;
    }
  }
}
