.container {
  // background-color: #f5f5f5;
  //   padding-left: 3rem;
  .pageDiv {
    padding: 0 3.5rem;
    .mainHeading {
      font-size: 30px;
      font-family: "Poppins-SemiBold";
      color: #363b64;
      padding: 30px 2px;
    }
  }
  .tabs {
    padding: 0 3.5rem;

    position: relative;
    .tab {
      background-color: #ffffff;

      border-radius: 10px;

      margin: 10px;
    }
    .tab1 {
      .list {
        border-radius: 10px;
      }
    }
    .tab2 {
      position: fixed;
      right: 0px;
      // left: 100px;
      padding: 0;
      height: 630px;
    }
  }
}
