.modalDialog {
  max-width: 1250px !important;
  margin: 10px;
  z-index: 999;
  .modalBody {
    margin: 1rem 4rem 6rem 4rem;
  }
}
.content {
  background-color: #f5f5f5 !important;
  border-radius: 8px !important;
}
.form {
  .formHead {
    padding: 36px 0 20px 0;
  }
  .formLabel {
    font-family: "Poppins-Regular";
    color: #363b64;
    font-weight: 600;
    font-size: 20px;
    padding-left: 170px;
  }
  .formControl {
    border-radius: 7px;
    border: 2px solid #9da1c1;
    background-color: #fcfcfc;
    font-family: "Poppins-Regular";
  }
  &::placeholder {
    color: #363b64;
    font-family: "Poppins-Regular";
  }
}
.selectProduct {
  font-family: "Poppins-Regular";
  color: #363b64;
  font-weight: 600;
  font-size: 16px;
  padding-left: 24rem;
}
.modalBody {
  background-color: #ffffff;
  max-width: 1442px !important;
  width: fit-content;
  padding: 0rem 2rem !important;
  .subPieceCol {
    padding: 30px 30px;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    align-items: flex-start;
    .subPiece {
      font-family: "Poppins-Medium";
      color: #363b64 !important;
      font-weight: 600;
      font-size: 18px;
      padding: 5px;
    }
    .sideLinks {
      display: block;
      text-decoration: none;
      font-family: "Poppins-Medium";
      color: #363b64 !important;
      font-weight: 600;
      font-size: 16px;
      padding: 20px 60px;
      &:hover {
        background-color: #3f8dfd;
        color: #ffffff !important;
      }
    }
  }
  .frontRow {
    .front {
      font-family: "Poppins-Medium";
      color: #363b64 !important;
      font-weight: 600;
      font-size: 18px;
      // padding: 20px;
    }
    .mainPiece {
      color: #3f8dfd;
      font-family: "Poppins-Medium";
    }
  }
}
