.noAutocomplete {
  position: absolute;
  color: #999;
  padding: 8px;
}

.autocomplete {
  position: absolute;
  top: 4.55rem;
  background-color: #fff;
  border: none;
  box-shadow: 1px 1px 2px #363b64;
  border-radius: 5px;
  z-index: 9999;
  list-style: none;
  margin-top: 5px;
  max-height: 300px;
  overflow-y: auto;
  padding-left: 0;
  width: 200px;
  color: #363b64;
}

.autocomplete li {
  padding: 3px 13px;
}

.autocomplete > .active,
.autocomplete li:hover {
  background-color: #fff;
  cursor: pointer;
  font-weight: 400;
}

.autocomplete li:not(:last-of-type) {
  border-bottom: 1px solid #363b64;
}
