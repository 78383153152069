@media screen and (max-width: 1274px) {
  .container {
    .left {
      flex: 1.5 !important;
    }
  }
}

.rightContainer {
  padding: 0rem 3.5rem;
  // background-color: #f5f5f5;
  // .pageDivChild {
  //   margin-bottom: 1.5rem;
  // }

  .mainHeading {
    width: fit-content;
    margin-bottom: 1rem;
    font-size: 30px;
    font-family: "Poppins-SemiBold";
    color: #363b64;
    padding: 40px 0px 15px;
  }
}

.table {
  // margin: auto;
  // height: 23.2rem;
  font-family: "Poppins-SemiBold";
  width: 100%;
  // height: 30rem;
  .tableStart {
    border: #f5f5f5 !important;
    .tableTitle {
      background-color: #fcfcfc;
    }
    .tableBody {
      background-color: #ffffff;
    }
  }
  .edit {
    height: 0.9rem;
    margin-bottom: 0.2rem;
    margin-right: 1rem;
    path {
      fill: #363b64;
    }
  }
}
