.modalContent {
  width: 85rem !important;
}
.bdaModal {
  // margin-left: 2.5vw !important;
  margin: 1rem 2rem !important;
}
.formGroup {
  // margin: auto;
  .formLabel {
    font-family: "Poppins-Medium";
    color: #363b64;
    font-size: 1.3rem;
    //   float: right;
    vertical-align: middle;
    align-items: center;
    padding: 0.5rem;
  }
  .formControl {
    width: 50%;
    border: 1px solid #363b64;
    background-color: #fcfcfc;
    font-family: "Poppins-Regular";
    color: #363b64;
    &:focus {
      box-shadow: none;
      border: 1px solid #363b64;
    }
  }
}
.job {
  width: 1.2rem;
  padding-bottom: 0.2rem;
  margin-right: 0.4rem;
}
.bdaModal {
  min-width: 80%;
}
.height {
  height: 66vh;
}
.bodyModal {
  padding: 0 !important;
  border-radius: 10px;
}
.next {
  // width: 1.2rem;
  // padding-bottom: 0.2rem;
  margin-left: 0.4rem;
}
