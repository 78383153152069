@media screen and (max-width: 1274px) {
  .container {
    background-color: #ffffff;
    .left {
      flex: 1.5 !important;
    }
  }
}
.rightContainer {
  padding: 0rem 3.5rem;
  .mainHeading {
    font-size: 30px;
    font-family: "Poppins-SemiBold";
    color: #363b64;
    padding: 30px 0px;
  }
}
.adminProfile {
  height: 30px;
  width: 30px;
  margin-right: 1rem;
}
.colorSpan {
  border-radius: 8px;
  // vertical-align: middle;
  padding: 12px !important;
  width: 30px;
}
.quantityDiv {
  width: 100%;
  justify-content: space-between;
  text-align: center;
  .value {
    border: 1px solid #b1afaf;
    border-radius: 8px;
    padding: 5px 0 3.8px 0;
    min-width: 1.7rem;
    align-items: center;
    font-family: "Poppins";
    margin: 0 4px;
    text-align: center;
    background-color: #fcfcfc;
  }
}

.editSvg {
  height: 35px;
  width: 35px;
  padding: 7px;
  margin-right: 8px;
  border: 1px solid #3f8dfd;
  border-radius: 5px;
  align-items: center;
}
.deleteSvg {
  height: 35px;
  width: 35px;
  padding: 7px;
  border: 1px solid #ff0000;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
}
.linkDetail {
  color: #3f8dfd;
  font-family: "Poppins-SemiBold";
}

.table {
  font-family: "Poppins-SemiBold";
  width: 100%;
}
.box {
  height: 1rem;
  margin-bottom: 0.1rem;
  padding-bottom: 0.1rem;
  margin-right: 0.6rem;
}
