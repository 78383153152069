* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
// .cbtndiv {
  .cbtnClass {
    width: fit-content;
    border: none;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.5px;
    padding: 0.7rem 1rem;
    border-radius: 5px;
    font-family: "Poppins-Medium";
  }
// }
