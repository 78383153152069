.Container {
  border-radius: 10px;
}
.add {
  height: 1.2rem;
  margin-bottom: 0.1rem;
  padding-bottom: 0.1rem;
  margin-right: 0.3rem;
  g {
    path {
      fill: #3f8dfd;
    }
  }
}
.rightContainer {
  .mainHeading {
    font-size: 30px;
    font-family: "Poppins-SemiBold";
    color: #363b64;
  }
}
.tShirt {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  float: right;
  position: relative;
  top: 10px;
  padding: 1rem;
}
.form {
  background-color: #ffffff;
  font-family: "Poppins-SemiBold";
  width: 100%;
  border-radius: 10px;
  box-shadow: 10px 10px 30px #00000008;
  .customFileInput {
    color: transparent;
  }
  .customFileInput::-webkit-file-upload-button {
    visibility: hidden;
  }
  .customFileInput::before {
    content: "";
    font-family: "Poppins-SemiBold";
    color: #363b64;
    display: inline-block;
    background: transparent;
    z-index: 3;
    // border: 1px solid #999;
    border-radius: 50%;
    padding: 50px 18px;
    height: 110px;
    width: 110px;
    border-radius: 50%;
    position: absolute;
    top: -60px;
    right: 70px;
    outline: none;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    font-size: 12px;
  }
  .customFileInput:hover::before {
    border-color: black;
  }
  .customFileInput:active {
    outline: 0;
  }
  .customFileInput:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
  .formGroup {
    position: relative;
    // margin: 0px 25px;
    margin-bottom: 30px;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      // background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      // width: 12px;
      padding-top: 10px;
      margin-top: 10rem;
      height: 5px;
      opacity: 0.1;
      // background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      background-color: #555;
    }

    .formLabel {
      margin-top: 0px;
      color: #363b64;
      font-family: "Poppins-Medium";
    }
    .sizeInputsRow {
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        // background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        // width: 12px;
        padding-top: 10px;
        margin-top: 10rem;
        height: 5px;
        opacity: 0.1;
        // background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
        background-color: #555;
      }
    }
    .formControl {
      font-size: 13.5px;
      font-family: Poppins-Regular;
      color: #363b64;
      background-color: #f5f5f5;
      border: none;
      border-radius: 10px;
      padding: 0.5rem 1rem !important;
      &:focus {
        box-shadow: none;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0;
      }
    }
    .sizechart {
      text-align: center;
      position: relative;
      color: #3f8dfd;
      margin: 0;
      padding: 0;
      max-width: 4.3rem;
      font-family: "Poppins-Medium";
    }

    .addsubpiece {
      margin-left: 10px;
      color: #3f8dfd;
    }
    .checkbox {
      padding: 0;
      max-width: 16rem;
      width: 13.3rem;
      margin-top: 1rem;
      position: relative;
      .sizeInputs {
        border: none !important;
        margin-left: 8px !important;
        font-family: "Poppins-Medium" !important;
        color: #3f8dfd !important;
        max-width: 5rem;
        margin-left: 0.8rem !important;
        padding: 0 !important;
        text-align: center !important;
        &:active {
          outline: none;
          box-shadow: none;
        }
        &:focus {
          border-radius: 0;
          border-bottom: 1px solid #3f8dfd !important ;
          box-shadow: none !important;
        }
      }
      .patanahi {
        //   position: relative;
        // margin-left: 8px;
        display: inline !important;
        width: 3rem !important;
        text-align: center;
        padding: 6px 9px;
        font-family: "Poppins-Regular" !important;
        border: 1px solid #b1afaf !important;
        background-color: #fcfcfc !important;
        border-radius: 12px !important;
        font-size: 0.9rem !important;
        color: #363b64 !important;

        &:focus {
          border: 1px solid #b1afaf !important;
          box-shadow: none;
        }
      }
      .kaataContainer {
        cursor: pointer;
        position: absolute;
        width: auto;
        top: -5px;
        right: 0;
        .kaata {
          height: 15px;
          width: 15px;
          border-radius: 50%;
        }
      }
    }
  }
}
