.svg {
  height: 1.4rem;
}

.add {
  height: 0.9rem;
  margin-bottom: 0.1rem;
  padding-bottom: 0.1rem;
  margin-right: 0.3rem;
  g {
    path {
      fill: #3f8dfd;
    }
  }
}

.inputStep {
  .cross {
    cursor: pointer;
    position: absolute;
    top: 1rem;
    width: 1.7rem;
    height: 1.7rem;
    border: 1px solid #3f8dfd;
    background-color: #3f8dfd;
    border-radius: 50px;
    right: 1rem;
    box-shadow: 2px 2px 10px #efe5e5;
    text {
      fill: #f5f5f5;
      font-size: 1rem;
    }
  }
  .input {
    background-color: #f5f5f5;

    font-size: 13.5px;
    font-family: Poppins-Regular;
    color: #363b64;
    // background-color: #f5f5f5;
    border: none;
    border-radius: 10px;
    padding: 0.5rem 1rem !important;
    &:focus {
      background-color: #e5e5e5;
      transition: ease-in-out 0.1s;
      box-shadow: none;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0;
    }
  }
  .label {
    margin-top: 10px;
    margin-left: 10px;
    // margin-top: 0px;
    color: #363b64;
    font-family: "Poppins-Medium";
  }
}
