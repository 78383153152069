.add {
  height: 0.9rem;
  margin-bottom: 0.1rem;
  padding-bottom: 0.1rem;
  margin-right: 0.3rem;
  g {
    path {
      fill: #3f8dfd;
    }
  }
}
.formGroup {
  position: relative;
  margin: 0px 30px;

  .formLabel {
    color: "#363B64";
    font-family: "Poppins-SemiBold";
    font-size: 15;
  }

  .addsubpiece {
    margin-left: 10px;
    color: #3f8dfd;
  }
  .checkbox {
    padding: 0;
    max-width: 16rem;
    width: fit-content;
    margin-top: 1rem;
    position: relative;
    .sizeInputs {
      border: none !important;
      margin-left: 8px !important;
      font-family: "Poppins-Medium" !important;
      color: #3f8dfd !important;
      max-width: 5rem;
      margin-left: 0.8rem !important;
      padding: 0 !important;
      text-align: center !important;
      &:active {
        outline: none;
        box-shadow: none;
      }
      &:focus {
        border-radius: 0;
        border-bottom: 1px solid #3f8dfd !important ;
        box-shadow: none !important;
      }
    }
    .patanahi {
      display: inline !important;
      width: 3.3rem !important;
      font-family: "Poppins-Regular" !important;
      border: 1px solid #b1afaf !important;
      background-color: #fcfcfc !important;
      border-radius: 12px !important;
      font-size: 0.9rem !important;
      color: #363b64 !important;

      &:focus {
        border: 1px solid #b1afaf !important;
        box-shadow: none;
      }
    }
    .kaataContainer {
      cursor: pointer;
      position: absolute;
      width: auto;
      top: -5px;
      right: 34px;
      .kaata {
        height: 15px;
        width: 15px;
        border-radius: 50%;
      }
    }
  }
}
