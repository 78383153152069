@media screen and (max-width: 1274px) {
  .container {
    .left {
      flex: 1.5 !important;
    }
  }
}

.container {
  padding: 0rem 3.5rem;
  width: 100%;
  padding: 0rem 3.5rem;
  .mainHeading {
    font-size: 30px;
    font-family: "Poppins-SemiBold";
    color: #363b64;
    // padding: 30px 2px 0px;
  }
  .add {
    height: 0.9rem;
    margin-bottom: 0.1rem;
    padding-bottom: 0.1rem;
    margin-right: 0.3rem;
    // width: 0.8rem;
  }
}

.middle {
  width: 100%;
  padding: 0rem 3.5rem;
}

.table {
  // margin: auto;
  // height: 23.2rem;
  font-family: "Poppins-SemiBold";
  width: 100%;
  // height: 30rem;
  .tableStart {
    border: #f5f5f5 !important;
    .tableTitle {
      background-color: #fcfcfc;
    }
    .tableBody {
      background-color: #ffffff;
    }
  }
}
.edit {
  height: 0.9rem;
  margin-bottom: 0.2rem;
  margin-right: 1rem;
  path {
    fill: #363b64;
  }
}
