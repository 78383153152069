.container {
  // background-color: #f5f5f5;
}
.rightContainer {
  padding: 0;
  margin: 0;
  .mainHeading {
    font-size: 30px;
    font-family: "Poppins-SemiBold";
    color: #363b64;
    padding: 30px 0px;
  }
}
