.Container {
  // background-color: #f5f5f5;
  padding: 1.5rem 1rem;
  .mainHeading {
    font-size: 25px;
    font-family: "Poppins-SemiBold";
    color: #363b64;
    margin-bottom: 1.5rem;
  }
}
