.Container {
  margin: auto;
  .form {
    background-color: #ffffff;
    font-family: "Poppins-SemiBold";
    width: 100%;
    color: #363b64;
    border-radius: 10px;
    margin: 1rem 0.2rem;
    .formGroup {
      padding: 17px 1rem;
      // margin: 1rem 0.2rem;
      .formLabel {
        font-weight: 600;
        font-family: "Poppins-Medium";
        color: #363b64 !important;
      }
      .col1 {
        padding-right: 6px;
        padding-left: 25px;
        margin-bottom: 13px;
        .formControl {
          background-color: #f2f2f2;
          color: #363b64;
          border: 0px !important;
          font-family: "Poppins-Regular";
          padding: 0.6rem 11px;
          border-radius: 8px;
          // background-color: #f5f5f5;
          &:focus {
            box-shadow: none !important;
          }
          &::placeholder {
            font-size: 13px;
            font-family: "Poppins-Regular";
            color: #a098ae;
          }
        }
      }
      .col2 {
        .search {
          height: 0.8rem;

          path {
            fill: #fff;
          }
        }
      }
    }
  }
  .resultsContainer {
    // background-color: #fff;
    margin: 1rem 0.2rem;
    padding: 30px 1.5rem;
    border-radius: 10px;
  }
  .tracker {
    padding: 40px 35px;
  }
}
