.sizeInputs {
  border: none !important;
  margin-left: 8px !important;
  font-family: "Poppins-Medium" !important;
  color: #3f8dfd !important;
  width: 3.3rem !important;
  padding: 0 !important;
  text-align: center !important;
  &:active {
    outline: none;
    box-shadow: none;
  }
  &:focus {
    outline: 1px solid #3f8dfd !important ;
    box-shadow: none !important;
  }
}
.patanahi {
  //   position: relative;
  margin-top: 0.4rem !important;
  margin-bottom: 0.4rem !important;
  margin-left: 5px;
  display: block !important;
  width: 3.7rem !important;
  text-align: center;
  padding: 6px 9px !important;
  font-family: "Poppins-Regular" !important;
  border: 1px solid #b1afaf !important;
  background-color: #fcfcfc !important;
  border-radius: 12px !important;
  font-size: 0.9rem !important;
  color: #363b64 !important;

  &:focus {
    border: 1px solid #b1afaf !important;
    box-shadow: none;
  }
}
.kaataContainer {
  cursor: pointer;
  position: absolute;
  width: auto;
  top: 22px;
  right: 2px;
  .kaata {
    height: 15px;
    width: 15px;
    border-radius: 50%;
  }
}
