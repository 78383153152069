.content {
  max-width: 270px !important;
}
.Header {
  color: #ff0000;
  font-size: 22px !important;
  font-family: "Poppins-Semibold";
  text-align: center;
}
.subtitle {
  color: #a098ae;
  font-family: "Poppins-Regular";
  font-size: 13px;
  font-weight: 100;
  padding-top: 10px;
  text-align: center;
}
