// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

//----------------- Modal
.content {
  padding: 1.3rem;
  max-width: 565px !important;
}
.brightkam {
  opacity: 0.3 !important;
}
.modalDialog {
  justify-content: center;
}
//---------------- ModalHeader
.modalheaderclass {
  display: block !important;
  border-bottom: none !important;
  .modalTitle {
    color: #363b64;
    font-family: "Poppins-Bold";
    // font-weight: 900;
    font-size: 20px;
  }
  .modalsubTitle {
    color: #a098ae;
    font-size: x-small;
    font-family: "Poppins-SemiBold"
  }
}

//----------------- Modal footer
.modalfooterclass {
  border-top: none !important;
  width: 100%;
  display: block !important;
}
