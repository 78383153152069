@media screen and (max-width: 1274px) {
  .container {
    .left {
      flex: 1.5 !important;
    }
  }
}
.container {
  background-color: #ffffff;
}
.rightContainer {
  padding-left: 3rem;
  .mainHeading {
    font-size: 30px;
    font-family: "Poppins-SemiBold";
    color: #363b64;
    padding: 30px 0px;
  }
}
.adminProfile {
  height: 30px;
  width: 30px;
}
.colorSpan {
  border: 1px solid #0a62c7;
  border-radius: 5px;
  padding: 2px 14px;
  margin-left: 30px;
}
.quantityDiv {
  flex-direction: column;
  .value {
    border: 1px solid #b1afaf;
    border-radius: 8px;
    padding: 3px 8px;
    align-items: center;
    margin-top: 20px;
    margin-left: 10px;
    font-size: 18px;
    font-style: "Poppins-Medium";
    text-align: center;
    background-color: #fcfcfc;
  }
}

.editSvg {
  height: 35px;
  width: 35px;
  padding: 7px;
  margin-right: 8px;
  border: 2px solid #3f8dfd;
  border-radius: 5px;
  align-items: center;
}
.deleteSvg {
  height: 35px;
  width: 35px;
  padding: 7px;
  border: 2px solid #ff0000;
  border-radius: 5px;
  align-items: center;
}
.linkDetail {
  color: #3f8dfd;
  font-family: "Poppins-SemiBold";
  text-decoration: none;
}

.table {
  font-family: "Poppins-SemiBold";
  width: 100%;
}
.firstitle {
  margin-left: 30px;
}
