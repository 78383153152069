.modalContent {
  border: none !important;
  border-radius: 0.5rem !important;
}
.cmodal {
  font-family: "Poppins-SemiBold";
  .Header {
    text-align: center;
    color: #1c2536;
  }
  .form {
    background-color: #ffffff;
    font-family: "Poppins-SemiBold";
    width: 100%;
    .formLabel {
      font-weight: 500;
      font-family: "Poppins";
      color: #363b64 !important;
      margin-top: 15px !important;
    }
    .colorcontrol {
      position: absolute;
      top: 0px;
      right: 43px;
      padding: 0px;
      outline: none;
      border-radius: 5px;
      &::-webkit-color-swatch {
        border: none;
        outline: none;
      }
      &::-moz-color-swatch {
        border: none;
        outline: none;
      }
      &:focus {
        outline: none;
      }
    }
    .addShades {
      position: relative;
      .formControl {
        background-color: #ffffff;
        color: #363b64;
        border: 1px solid #e3e3e3;
        border-radius: 8px;
        width: 95%;
        margin: 7px 0;
        &::placeholder {
          font-family: "Poppins";
        }
      }

      .colorControl {
        position: absolute;
        top: 0;
        right: 43px;
        padding: 0px;
        border: none;
        border-radius: 5px;
        outline: none;
        &:focus {
          outline: none;
        }
        &::-webkit-color-swatch {
          border: none;
          outline: none;
        }
        &::-moz-color-swatch {
          border: none;
          outline: none;
        }
      }
      .kaataContainer {
        cursor: pointer;
        position: absolute;
        width: auto;
        top: -9px;
        right: 22px;
        .kaata {
          height: 15px;
          width: 15px;
          border-radius: 50%;
        }
      }
    }

    .popover {
      position: absolute;
      z-index: 2;
      top: 208px;
      left: 441px;
      .cover {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
      }
    }
  }
}
