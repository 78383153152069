.firstRow {
  background: #fff;
  box-shadow: 0px 5px 30px #0000000d;
  border-radius: 10px;
  min-width: 12rem;
  padding: 2rem 1.3rem;
  justify-content: flex-start !important  ;
  .secondCol {
    .figures {
      // margin: auto !important;
      font-family: "Poppins-Bold";
      color: #363b64;
      font-size: 30px;
    }
    .totalWhatever {
      color: #a098ae;
      font-size: 0.9rem;
      font-family: "Poppins-Regular";
    }
  }
}
